<!-- eslint-disable vue/html-self-closing -->
<template>
  <!-- BEGIN: Content-->
  <div
    id="preview"
    class="app-content content ms-0"
  >
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-wrapper container-xxl p-0">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                Preview {{ convo.title }} for
                <strong class="text-dark">{{ user.name }}</strong>
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 buttons-print">
          <div class="mb-1 breadcrumb-right">
            <a
              :class="{'disabled': disablePrint}"
              class="btn btn-outline-dark btn-sm"
              @click="print"
            ><i data-feather="printer"></i> Print / Export to pdf
              <div
                v-if="exporting"
                class="loading custom-loading position-relative"
                style="width: 20px;height: 20px;left:0px"
              >
                <div class="effect-1 effects" />
                <div class="effect-2 effects" />
                <div class="effect-3 effects" />
              </div>
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <hr />
          </div>
        </div>
      </div>

      <div class="content-body mt-3">
        <div class="row">
          <div class="col-sm-6">
            <p
              v-if="missingFields"
              class="text-danger"
            >
              <i data-feather="alert-circle"></i> There are empty fields
            </p>
          </div>
          <div class="col-sm-6 d-flex justify-content-sm-end buttons-print">
            <a
              class="me-2 text-dark"
              @click="toggleAllSections(false)"
            ><i data-feather="maximize-2"></i> Open all</a>
            <a
              class="text-dark"
              @click="toggleAllSections(true)"
            ><i data-feather="minimize-2"></i> Close all</a>
          </div>
        </div>

        <section
          id="senior__call__form"
          class="senior__call__form"
        >
          <div class="row">
            <div class="col">

              <PreviewSection
                v-for="(section, index) in sections"
                :key="section.id"
                :section="section"
                :index="(index + 1)"
              />

            </div>
          </div>

          <div class="row senior__call__form__actions buttons-print">
            <div class="col-12">
              <hr />
            </div>
            <div class="col-sm-12">
              <div class="save__buttons mb-2">
                <a
                  v-if="convo.open_applicants"
                  class="btn btn-success"
                  data-bs-toggle="modal"
                  data-bs-target="#modalSumitSeniorCall"
                  :class="{'disabled': missingFields}"
                  @click="showModal = !showModal"
                >Submit candidacy</a>
                <!-- añadir clase disabled si hay campos pendientes de rellenar -->
                <!-- el botón ok del modal de confirmación, quitarlo y que después de 2 segundos salte a finalize-ok.php -->
              </div>
              <p>
                <small>Applicants can submit their applications regardless of whether the referees and the host(s) institutions have uploaded their letters and EOIs. The system allows applicants to monitor file uploading by both referees and host institution(s). Please bear in mind that <strong>incomplete applications will not be considered for evaluation.</strong></small>
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
    <SendConvoModal
      :show="showModal"
    />
  </div>
  <!-- END: Content-->
</template>

<script>
import { mapGetters } from 'vuex'
import html2pdf from 'html2pdf.js'
import Vue from 'vue'
import PreviewSection from './partials/preview/PreviewSection.vue'
import SendConvoModal from './partials/modals/SendConvoModal.vue'

export default {
  components: {
    PreviewSection,
    SendConvoModal,
  },
  data() {
    return {
      missingFields: false,
      showModal: false,
      disablePrint: false,
      exporting: false,
    }
  },
  computed: {
    ...mapGetters({
      convo: 'convo/convo',
      user: 'auth/user',
      sections: 'convo/sections',
    }),
  },
  async mounted() {
    this.loading = true
    if (!this.convo.id) {
      if (this.$route.name.includes('senior-call')) {
        await this.$store.dispatch('convo/fetch')
      } else if (this.$route.name.includes('academy-call')) {
        await this.$store.dispatch('convo/academyFetch')
      }
    }

    if (Object.keys(this.sections) && Object.keys(this.sections).length === 0) {
      await this.$store.dispatch('convo/fetchSections', this.convo.id)
    }

    this.missingFields = this.sections.filter(obj => obj.status !== 2)
    if (this.missingFields) {
      this.missingFields = this.sections.filter(obj => obj.status !== 2).length !== 0
    }

    feather.replace({
      width: 14,
      height: 14,
    })
  },
  methods: {
    toggleAllSections(closed) {
      this.$store.dispatch('preview/toggleSections', closed)
      if (!closed) {
        this.$store.dispatch('preview/loadAllGroups')
      }
    },
    print() {
      this.exporting = true
      Vue.prototype.$http.get(`${Vue.prototype.$groupUrl}/convo/${this.convo.id}/get-applicant-pdf`, {
        responseType: 'blob',
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const a = document.createElement('a')
        a.href = url
        a.download = `Call preview ${this.convo.title}.pdf`
        a.click()
        window.URL.revokeObjectURL(url)
        this.exporting = false
      })
    },
  },
}
</script>
